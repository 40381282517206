<template>
  <div class="c--bottom_referral referral" :class="{ 'home-container-padding': !isMyEsims }">
    <div
      :class="{
        'container-fluid px-20 px-sm-30': !isMyEsims,
        'h-100': isMyEsims,
      }"
    >
      <div :class="{ 'airgsm-container mx-auto': !isMyEsims, 'h-100': isMyEsims }">
        <div class="referral-wrapper" :class="{ 'my-esims-referral h-100': isMyEsims }">
          <div class="referral-content">
            <div class="referral-img">
              <nuxt-img
                src="/assets/images/components/referral/img_referral.png"
                alt="Airalo | Use your Free Credit"
                width="160"
                height="160"
                loading="lazy"
              />
            </div>
            <div class="referral-container" :class="{ wide: isMyEsims }">
              <div class="referral-texts d-flex flex-column justify-content-start align-items-center">
                <h2 class="referral-text-tp-2-2">
                  {{ $t('scenes.my.esims.refferal-empty-title') }}
                </h2>
                <p>{{ referralGiveAmountMessage }}</p>
              </div>
            </div>
            <div class="referral-container buttons" :class="{ wide: isMyEsims }">
              <div class="referral-button">
                <b-button block variant="b-secondary" @click.prevent="toReferralPage">
                  {{ $t('scenes.my.esims.refferal-button') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'BottomReferral',

    props: {
      isMyEsims: {
        type: Boolean,
        required: false,
        default: false,
      },
      isStore: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      referralGiveAmountMessage() {
        return this.$t('scenes.my.esims.refferal-empty-subtitle.v2', {
          amount: this.$store.getters['multiCurrency/get_referral_give_amount'],
        })
      },
    },

    methods: {
      toReferralPage() {
        let screen_type = 'my_esims'
        if (!this.isMyEsims) screen_type = this.isStore ? 'store' : 'other_pages'
        this.$utils.sendEvent('referralBannerEvent', {
          screen_type,
        })

        this.$router.push(
          this.localePath({
            name: this.$store.state.auth.loggedIn ? 'profile-index-referral' : 'referral',
          })
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .referral {
    ::v-deep .container-fluid {
      @media screen and (max-width: 575px) {
        padding: 0 20px;
      }
    }

    &-wrapper {
      border-radius: 22px;
      background: linear-gradient(90deg, #ff5f6d 0%, #ffc371 100%);
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
      padding: 40px 0;
      margin: 10px 0;

      &.my-esims-referral {
        margin: 0;
      }

      [dir='rtl'] & {
        background: linear-gradient(270deg, #ff5f6d 0%, #ffc371 100%);
      }

      @media screen and (max-width: 575px) {
        padding: 20px 20px;
        margin: 0;
      }

      @media screen and (min-width: 576px) and (max-width: 1023px) {
        padding: 30px 0;
      }
    }

    &-container {
      width: 100%;

      @media screen and (min-width: 576px) {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        gap: 30px;
      }

      &.wide {
        .referral-texts {
          @media screen and (min-width: 576px) and (max-width: 1023px) {
            grid-column: span 6 / span 6;
            grid-column-start: 4;
          }

          @media screen and (min-width: 1024px) {
            grid-column: span 8 / span 8;
            grid-column-start: 3;
          }
        }

        .referral-button {
          @media screen and (min-width: 576px) and (max-width: 1023px) {
            grid-column: span 6 / span 6;
            grid-column-start: 4;
          }

          @media screen and (min-width: 1024px) {
            grid-column: span 8 / span 8;
            grid-column-start: 3;
          }
        }
      }

      &.buttons {
        .my-esims-referral & {
          margin-top: auto;
        }
      }
    }

    &-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      .my-esims-referral & {
        height: 100%;
      }
    }

    &-texts {
      margin: 20px 0;

      @media screen and (min-width: 576px) and (max-width: 1023px) {
        grid-column: span 6 / span 6;
        grid-column-start: 4;
      }

      @media screen and (min-width: 1024px) {
        grid-column: span 4 / span 4;
        grid-column-start: 5;
      }

      h2,
      p {
        text-align: center;
        margin: 0;
      }

      h2 + p {
        margin-top: 10px;
      }

      p {
        font-size: 0.9375rem;
        line-height: 1.4;
        color: #ffffff;
      }
    }

    &-button {
      grid-column: span 4 / span 4;
      grid-column-start: 5;

      @media screen and (min-width: 576px) and (max-width: 1023px) {
        grid-column: span 6 / span 6;
        grid-column-start: 4;
      }
    }
  }
</style>
