var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--bottom_referral referral",
    class: {
      'home-container-padding': !_vm.isMyEsims
    }
  }, [_c('div', {
    class: {
      'container-fluid px-20 px-sm-30': !_vm.isMyEsims,
      'h-100': _vm.isMyEsims
    }
  }, [_c('div', {
    class: {
      'airgsm-container mx-auto': !_vm.isMyEsims,
      'h-100': _vm.isMyEsims
    }
  }, [_c('div', {
    staticClass: "referral-wrapper",
    class: {
      'my-esims-referral h-100': _vm.isMyEsims
    }
  }, [_c('div', {
    staticClass: "referral-content"
  }, [_c('div', {
    staticClass: "referral-img"
  }, [_c('nuxt-img', {
    attrs: {
      "src": "/assets/images/components/referral/img_referral.png",
      "alt": "Airalo | Use your Free Credit",
      "width": "160",
      "height": "160",
      "loading": "lazy"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "referral-container",
    class: {
      wide: _vm.isMyEsims
    }
  }, [_c('div', {
    staticClass: "referral-texts d-flex flex-column justify-content-start align-items-center"
  }, [_c('h2', {
    staticClass: "referral-text-tp-2-2"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('scenes.my.esims.refferal-empty-title')) + "\n              ")]), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.referralGiveAmountMessage))])])]), _vm._v(" "), _c('div', {
    staticClass: "referral-container buttons",
    class: {
      wide: _vm.isMyEsims
    }
  }, [_c('div', {
    staticClass: "referral-button"
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "b-secondary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.toReferralPage.apply(null, arguments);
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t('scenes.my.esims.refferal-button')) + "\n              ")])], 1)])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }